export enum LoginType {
	LoyallSMS,
	VIPPS,
}

export enum AuthenticationTypes {
	Mobile,
	Vipps,
	JWT,
}

export enum MainMenu {
	Benefits,
	Profile,
	Purchases,
	Settings,
	Support,
	Logout,
}

export enum TopbarMenu {
	Resize,
	Minimize,
	Close,
	Refresh,
}

export enum BenefitReleaseModes {
	CreateDiscountCode,
	ByTimer,
}

export enum AuthState {
	Unauthorized = "Unauthorized",
	//SMS sent to a Member
	PendingVerification = "PendingVerification",
	//SMS sent to a NonMember
	Unregistered = "Unregistered",
	//Redirected to VIPPS API via LoyallProxy
	PendingSSOVerification = "PendingSSOVerification",
	PendingConsent = "PendingConsent",
	PendingBank = "PendingBank",
	Registered = "Registered",
	MemberVerificationFailed = "MemberVerificationFailed",
	NonMemberVerificationFailed = "NonMemberVerificationFailed",
	SSOVerificationFailed = "SSOVerificationFailed",
	AgreementFailed = "AgreementFailed",
	Authorized = "Authorized",
	AuthorizedByToken = "AuthorizedByToken",
	OtherError = "OtherError",
}

export enum ProductIntegrationSettings {
	Name = "IntegrationSettings.Product.Name",
	Price = "IntegrationSettings.Product.Price",
	Discount = "IntegrationSettings.Product.Discount",
	DiscountPercentage = "IntegrationSettings.Product.DiscountPercentage",
	IsInSale = "IntegrationSettings.Product.IsInSale",
	Currency = "IntegrationSettings.Product.Currency",
}

export enum LoginButtonStyle {
	Single,
	Double,
}

export enum WidgetSize {
	Small = "Small",
	Main = "Main",
	Full = "Full",
	Minimize = "Minimize",
}

export enum WidgetMode {
	Open = "Open",
	Close = "Close",
}

export enum ProfilePageItems {
	Profile,
	Consent,
	Cards,
}

export enum SettingPageItems {
	Language,
	Theme,
	Profile,
}

export enum BenefitTypes {
	Bonus,
	Gift,
	Offer,
}

export enum ConsoleType {
	Error,
	Trace,
	Info,
	Warning,
}

export enum TemplateElements {
	texts = "texts",
	buttons = "buttons",
}

export enum Components {
	Login,
	Minimize,
	Close,
	Bonus,
	Gift,
	Offer,
}

export enum RedeemType {
	giftcard = "giftcard",
	offer = "offer",
}

export enum LanguageCodes {
	en,
	no,
	sv,
	da,
	de,
	el,
	fi
}

export enum ThemeCodes {
	Loyall,
	Retro,
	Dark,
	Light,
}
